import { Injectable } from '@angular/core';
import { Parse } from 'parse';
import { BehaviorSubject } from 'rxjs';
import { Plugins } from '@capacitor/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';

//const { Network } = Plugins;

@Injectable({
	providedIn: 'root'
})
export class ParseService {

	private onlineState = new BehaviorSubject<boolean>(false);
	private appState = new BehaviorSubject<Parse.Object>(null);

	//private URL = 'http://localhost:1337/parse';
    //private URL = 'http://192.168.2.113:1337/parse';

    private connected = new BehaviorSubject('Keine Verbindung');

    punkte = {
        station1: 0,
        station2: 0,
        station3: 0,
        station4: 0,
        station5: 0,
        getallpunkte: function(){
            return this.station1+this.station2+this.station3+this.station4+this.station5;
        }
    };

	constructor(private storage: Storage, private router: Router) {        		
		/*Network.getStatus().then(status => {
			this.onlineState.next(status.connected);
		})
		
		let handler = Network.addListener('networkStatusChange', (status) => {
			this.onlineState.next(status.connected);
			
		});
        */
	}

    url;
    ip;

	async init(url) {
        
        Parse.initialize('Lakost');
		Parse.serverURL = url.url;

        //this.connected.next(url.name);
        this.url = url;

		let query = new Parse.Query('Quiz');
		query.equalTo("status", 1);
		query.find().then(res => {

            if(res.length > 0){
				this.appState.next(res[0]);
			}
		});
		let subscription = await query.subscribe();

		subscription.on('create', (object, response) => {
            this.appState.next(object);
		});

        subscription.on('enter', (object, response) => {
            this.appState.next(object);
		});

		subscription.on('update', (object, original, response) => {
			this.appState.next(object);
		});

        subscription.on('leave', (object, original, response) => {
            this.appState.next(null);
            this.router.navigate(['/home']);
		});

        subscription.on('open', (object, response) => {
            this.connected.next(this.url.name);
            this.ip = this.url.ip;
		});


        Parse.LiveQuery.on('close', () => {
            this.connected.next('keine Verbindung');
            //this.appState.next(null);
            //this.router.navigate(['/home']);
        });

	}

	getState(){
		return this.appState.asObservable();
	}

    getStateSingle(){
		return this.appState.getValue();
	}

	isOnline(){
		return this.onlineState.value;
	}

	getOnline(){
		return this.onlineState.asObservable();
	}

    getSchueler(){
        var promise = new Promise((resolve, reject) => {
            this.storage.get('userid').then(userid => {
                let query = new Parse.Query('Schueler');
                query.equalTo("objectId", userid);
                query.find().then(res => {
                    resolve(res[0]);
                });
            });
        });
        return promise;
    }

	createSchueler(name){

        var promise = new Promise((resolve, reject) => {
            let s = new Parse.Object.extend("Schueler");
            s = new s();
            s.set("name", name);
            s.set('punkte1', 0);
            s.set('punkte2', 0);
            s.set('punkte3', 0);
            s.set('punkte4', 0);
            s.set('punkte5', 0);
            s.set("quizident", this.appState.value.get('ident'));
            return s.save().then(res => {
                this.storage.set("userid", res.id).then((res) => {
                    resolve(null);
                });
            })
        });

        return promise;        
	}

    setPunkte(station, punkte){
        this.punkte[station] = punkte;
    }

    getPunkte(){
        return this.punkte;
    }

    getAllSchueler(){

        var promise = new Promise((resolve, reject) => {

            this.storage.get('quizident').then(res => {
                let query = new Parse.Query('Schueler');
                query.equalTo("quizident", res);
                query.find().then(res => {
                    resolve(res);
                })
            })

        });

        return promise;
        
    }

    safeFile(file){
        let parseFile = new Parse.File("image.jpeg", {base64: file}, "image/jpg");
        return parseFile.save();
    }

    async getAvatar(){
        let schueler:any = await this.getSchueler();
        
        if(schueler.get('bild') != undefined){
            return schueler.get('bild');
        }else{
            return '/assets/images/avatar_default.jpg';
        }
    }

    getConnectedParseServer(){
        return this.connected.asObservable();
    }

    getUrlObject(){
        return this.ip;
    }
	
}
