import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'anmeldung',
    loadChildren: () => import('./anmeldung/anmeldung.module').then( m => m.AnmeldungPageModule)
  },
  {
    path: 'warten',
    loadChildren: () => import('./warten/warten.module').then( m => m.WartenPageModule)
  },
  {
    path: 'station1-einleitung',
    loadChildren: () => import('./station1-einleitung/station1-einleitung.module').then( m => m.Station1EinleitungPageModule)
  },
  {
    path: 'station1-ergebnis',
    loadChildren: () => import('./station1-ergebnis/station1-ergebnis.module').then( m => m.Station1ErgebnisPageModule)
  },
  {
    path: 'station1-quiz',
    loadChildren: () => import('./station1-quiz/station1-quiz.module').then( m => m.Station1QuizPageModule)
  },
  {
    path: 'station2-einleitung',
    loadChildren: () => import('./station2-einleitung/station2-einleitung.module').then( m => m.Station2EinleitungPageModule)
  },
  {
    path: 'station2-quiz',
    loadChildren: () => import('./station2-quiz/station2-quiz.module').then( m => m.Station2QuizPageModule)
  },
  {
    path: 'station2-ergebnis',
    loadChildren: () => import('./station2-ergebnis/station2-ergebnis.module').then( m => m.Station2ErgebnisPageModule)
  },
  {
    path: 'station3-einleitung',
    loadChildren: () => import('./station3-einleitung/station3-einleitung.module').then( m => m.Station3EinleitungPageModule)
  },
  {
    path: 'station3-quiz',
    loadChildren: () => import('./station3-quiz/station3-quiz.module').then( m => m.Station3QuizPageModule)
  },
  {
    path: 'station3-ergebnis',
    loadChildren: () => import('./station3-ergebnis/station3-ergebnis.module').then( m => m.Station3ErgebnisPageModule)
  },
  {
    path: 'station4-einleitung',
    loadChildren: () => import('./station4-einleitung/station4-einleitung.module').then( m => m.Station4EinleitungPageModule)
  },
  {
    path: 'station4-quiz',
    loadChildren: () => import('./station4-quiz/station4-quiz.module').then( m => m.Station4QuizPageModule)
  },
  {
    path: 'station4-ergebnis',
    loadChildren: () => import('./station4-ergebnis/station4-ergebnis.module').then( m => m.Station4ErgebnisPageModule)
  },
  {
    path: 'station4-alk-einleitung',
    loadChildren: () => import('./station4-alk-einleitung/station4-alk-einleitung.module').then( m => m.Station4AlkEinleitungPageModule)
  },
  {
    path: 'station4-alk-quiz',
    loadChildren: () => import('./station4-alk-quiz/station4-alk-quiz.module').then( m => m.Station4AlkQuizPageModule)
  },
  {
    path: 'station4-alk-ergebnis',
    loadChildren: () => import('./station4-alk-ergebnis/station4-alk-ergebnis.module').then( m => m.Station4AlkErgebnisPageModule)
  },
  {
    path: 'station5-einleitung',
    loadChildren: () => import('./station5-einleitung/station5-einleitung.module').then( m => m.Station5EinleitungPageModule)
  },
  {
    path: 'station5-ergebnis',
    loadChildren: () => import('./station5-ergebnis/station5-ergebnis.module').then( m => m.Station5ErgebnisPageModule)
  },
  {
    path: 'station5-quiz',
    loadChildren: () => import('./station5-quiz/station5-quiz.module').then( m => m.Station5QuizPageModule)
  },
  {
    path: 'einzelauswertung',
    loadChildren: () => import('./einzelauswertung/einzelauswertung.module').then( m => m.EinzelauswertungPageModule)
  },
  {
    path: 'gesamtauswertung',
    loadChildren: () => import('./gesamtauswertung/gesamtauswertung.module').then( m => m.GesamtauswertungPageModule)
  },
  {
    path: 'wartenjoint',
    loadChildren: () => import('./wartenjoint/wartenjoint.module').then( m => m.WartenjointPageModule)
  },
  {
    path: 'jointistheiss',
    loadChildren: () => import('./jointistheiss/jointistheiss.module').then( m => m.JointistheissPageModule)
  },
  {
    path: 'jointauswertung',
    loadChildren: () => import('./jointauswertung/jointauswertung.module').then( m => m.JointauswertungPageModule)
  },
  {
    path: 'jointauswertungalles',
    loadChildren: () => import('./jointauswertungalles/jointauswertungalles.module').then( m => m.JointauswertungallesPageModule)
  },
  {
    path: 'danke',
    loadChildren: () => import('./danke/danke.module').then( m => m.DankePageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
