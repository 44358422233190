/* eslint-disable max-len */
import { ParseService } from './services/parse.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';

import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    private urls_ipad = [
        {
            name: 'http://192.168.0.50',
            health: 'http://192.168.0.50:1337/parse/health',
            url: 'http://192.168.0.50:1337/parse',
            ip: '192.168.0.50'
        },{
            name: 'http://192.168.2.208',
            health: 'http://192.168.2.208:1337/parse/health',
            url: 'http://192.168.2.208:1337/parse',
            ip: '192.168.2.208'
        },{
            name: 'http://localhost',
            health: 'http://localhost:1337/parse/health',
            url: 'http://localhost:1337/parse',
            ip: 'localhost'
        }
    ];

    private urls_desktop = [
       {
            name: 'https://parse.lakost.app',
            health: 'https://parse.lakost.app/parse/health',
            url: 'https://parse.lakost.app/parse',
            ip: 'https://parse.lakost.app'
        }
    ];



    constructor(private router: Router, private parse: ParseService, private storage: Storage, private platform: Platform, private http: HttpClient) {
        
        this.parse.init(this.urls_desktop[0]);
         
        /*
        
        let connected = false;
        const interval = setInterval(() => {

            let urls;
            if(this.platform.is("desktop")){
                urls = this.urls_desktop;
            }else{
                urls = this.urls_ipad;
            }

            urls.forEach(url => {
                this.http.get(url.health, {responseType: 'text', observe: 'response'})
                .pipe(first())
                .subscribe(resp => {
                        if(resp.status === 200){
                            if(!connected){
                                connected = true;
                                clearInterval(interval);
                                this.parse.init(url);
                            }
                        }
                });
            });

        }, 3000);
        */
 
        //this.parse.init();
    }

    async ngOnInit() {
       
        
        await this.storage.create();
        if(!this.platform.is("desktop")){
            await StatusBar.setStyle({ style: Style.Dark });
        }
    }

    onRightClick(event) {
        //return false;
    }
}
